<template>
    <div>
      <!-- Table Container Card -->
      <!-- Disabled add/remove hotel confirm modal -->
      <confirm-disable
        ref="disableModal"
        :msg="disableMsg"
        @on-submit="addRemoveDisabled"
        @on-hide="resetDisabledStatus"
      />

      <confirm-disable
        ref="delayHotelModal"
        :msg="delayHotelMsg"
        @on-submit="addRemovedelayHotel"
        @on-hide="resetdelayHotelStatus"
      />

      <resort-translation-modal
        
        ref="resortTranslationModal"
        :is-loaded="isLoaded"
        :resort-details="resortDetails"
        :toggle-modal="toggleModal"
        @on-cancel="onCancel"
        @on-submit="changeResortTranslations"
      />

      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Search Resort Name -->
            <b-col cols="12" md="4">
                <b-form-group
                    label="Resort"
                    label-for="mc-resort"
                >
                    <v-select
                      v-model="resortName"
                      :options="DestinationList"
                      class="destination"
                      placeholder="Select Resort"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="true"
                    />
                </b-form-group>
            </b-col>
  
            <b-col cols="12" md="4">
              <b-form-group
                label="Select Disabled Resort Status"
                label-for="mc-select-status"
              >
                <v-select
                  v-model="resortStatus"
                  :options="statusFilter"
                  class="resortStatus"
                  placeholder="Select Disabled Resort Status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="(val) => val.value"
                  :clearable="true"
                />
              </b-form-group>
            </b-col>
  
            <b-col cols="12" md="4">
                <label>Filter by WhiteLabel</label>
                <v-select
                v-model="wlCode"
                :options="whitelabelList"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
                :reduce="(val) => val.value"
                placeholder="Select Whitelabel"
                :clearable="true"
                />
            </b-col>

          </b-row>
        </div>
      </b-card>
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="6" lg="6" sm="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" >
              <label>Show {{ selected }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <export-csv :data="exportResults" name="ResortTranslation.csv">
                  <template>
                    <b-button class="mr-1" variant="secondary" :disabled="exportResults.length < 1">
                      <div v-if="exportResults.length < 1" class="spinner-border spinner-border-sm" />
                      <span v-else>Export</span>
                    </b-button>
                  </template>
                </export-csv>
                <div class="drop align-center">
                  <div class="helper" />
                  <label class="btn btn-primary">
                    Import
                    <input
                      type="file"
                      accept="csv/*"
                      name="doc"
                      @change="onChange"
                    >
                  </label>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <b-table
          v-if="!contentLoading"
          ref="refContentListTable"
          class="position-relative"
          :items="items"
          responsive
          :search-options="{
            enabled: true,
            externalQuery: resortName,
          }"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >
  
          <template #cell(resort_id)="data">
            {{ data.item && data.item.id ? data.item.id : '-' }}
          </template>

          <template #cell(resort_name)="data">
            {{ data.item && data.item.name ? data.item.name : '-' }}
          </template>

          <!--language wise dynamic column and resort name in it-->

          <template #cell(disable_resort)="data">
            <div>
              <b-form-checkbox
                v-model="data.item.isDisabled"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="
                  openDisableModal(
                    data.item.id,
                    data.item.name,
                    data.item.isDisabled
                  )
                "
              >
                <span class="switch-icon-left">
                  <feather-icon icon="TrendingUpIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="DiscIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </template>
          <template #cell(extend_hotel_search)="data">
            <div>
              <b-form-checkbox
                v-model="data.item.extendHotelSearch"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="
                  opendelayHotelModal(
                    data.item.id,
                    data.item.name,
                    data.item.extendHotelSearch
                  )
                "
              >
                <span class="switch-icon-left">
                  <feather-icon icon="XIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="CheckIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />  
              </template>
              <b-dropdown-item
                @click="openResortModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div v-if="contentLoading">
          <Loader />
        </div>
  
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ totalResort >= 1 ? startIndex + 1 : startIndex }} to
                {{ endIndex > totalResort ? totalResort : endIndex }} of
                {{ totalResort }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalResort"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
</template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BBadge,
    BButton,
    BFormCheckbox,
    VBModal,
    BForm
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import {
    perPageOptions,
    perPage,
    currentPage,
    resConditionCheck,
    RoleEnum,
    statusFilter,
    resolveUserStatusVariant,
    resolveUserStatusName
  } from "@/helpers/constant";
  import ResortTranslationModal from "@/views/modal/ResortTranslationModal.vue";
  import errorResponseHandler from "@/services/errorHandler";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import checkLoginRole from "@/helpers/checkLoginRole";
  import accessRightCheck from "@/helpers/accessRightCheck";
  import { VueAutosuggest } from "vue-autosuggest";
  import Loader from "@/layouts/skeloton-loader/Loader.vue";
  import ResortTranslationService from "../../../services/resort-translation/resortTranslation.service"
  import whitelabelService from "@/services/whitelabel/whitelabel.service";
  import BookingService from '@/services/booking/booking.service';
  import LanguageService from '@/services/language/language.service';
  import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";
  import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
  import axios from "axios";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BInputGroup,
      BTable,
      BDropdown,
      BDropdownItem,
      BPagination,
      BInputGroupPrepend,
      vSelect,
      VueAutosuggest,
      BFormGroup,
      Loader,
      BBadge,
      ConfirmDisable,
      ConfirmDelete,
      BButton,
      BFormCheckbox,
      BForm,
      // FormModal,
      ResortTranslationModal
    },
    data() {
      return {
        contentLoading: true,
        sortBy: "",
        perPageOptions,
        perPage,
        isSortDirDesc: false,
        currentPage,
        toggleModal: false,
        items: [],
        deleteMsg: "",
        RoleEnum,
        startIndex: 0,
        endIndex: 0,
        errorData: "",
        totalResort: 0,
        checkLoginRole,
        checkAccess: {},
        noResults: false,
        tableColumns: [
        { key: "resort_id" },
        { key: "resort_name" , sortable: true},
        //language wise resort names
        { key: "disable_resort" },
        { key: "action" },
        { key:  "extend_hotel_search", label: "DELAY HOTEL"}
        ],
        results: [],
        timeout: null,
        selected: null,
        debounceMilliseconds: 250,
        resortStatus: "",
        ResortStatus: [],
        isDisabled: "", // [ 0 = false, 1 = true ]
        statusFilter,
        resolveUserStatusVariant,
        resolveUserStatusName,
        status2: "",
        disableMsg: "",
        titleMsg: "",
        name: "",
        resortId: "",
        wlCode: 1,
        whitelabelList: [],
        wl_code: axios.defaults.headers.wl_code,
        DestinationList: [],
        resortName: "",
        languagesList: [],
        languages: [],
        items2: [],
        resortDetails: {},
        isLoaded: false,
        exportResults: [],
        delayHotelMsg: '',
        status3: ""
      };
    },
  
    watch: {
      currentPage: {
        handler(val) {
          this.getResortList();
          this.getExportData();
          this.$router
            .push({
              name: "resort-translation",
              query: { page: val, rows: this.perPage },
            })
            .catch(() => {});
        },
      },
  
      perPage: {
        handler(val) {
          // this.currentPage = 1;
          this.getResortList();
          this.getExportData();
          this.$router
            .push({
              name: "resort-translation",
              query: { page: this.currentPage, rows: val },
            })
            .catch(() => {});
        },
      },
  
      sortBy: {
        handler() {
          this.getResortList();
          this.getExportData();
        },
      },

      isSortDirDesc: {
        handler() {
          this.getResortList();
          this.getExportData();
        },
      },
  
      resortStatus: {
        handler(val) {
          this.currentPage = 1;
          this.getResortList();
          this.getExportData();
        },
      },
      
      resortName: {
        handler() {
          this.getResortList();
          this.getExportData();
        },
      },

      wlCode: {
      async handler(val) {
        if (val === null) {
          this.wlCode = 1;
        }
        this.getResortList();
        this.getHotelDestination();
        this.getExportData();
      },
    },
    },
  
    async mounted() {
      this.checkAccess = accessRightCheck(this.$route.meta.module.name);
      await this.languageList();
      // if (this.checkLoginRole() !== RoleEnum.SuperAdmin && !this.checkAccess.Edit) {
      this.tableColumns = [
        { key: "resort_id" },
        { key: "resort_name", sortable: true },
      ];

      let languagesArr = this.languagesList
      if(languagesArr.length) {
        languagesArr.forEach(item => {
          this.tableColumns.push({
              key: item.name,
              sortable: false
          })
        })
      }

      this.tableColumns.push({ key: "disable_resort" })
      this.tableColumns.push({ key:  "extend_hotel_search", label: "DELAY HOTEL"})
      this.tableColumns.push({ key: "action" });
  
      await this.getResortList();
      this.getExportData();
      if (this.wl_code == 1) {
        await this.getWhitelabelList();
      }
      this.getHotelDestination()
      if (this.$route.query) {
        this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
        this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
        // this.wlCode = this.$route.query.wl_code ? this.$route.query.wl_code : 1;
      }
    },
  
    methods: {
      async getResortList() {
        const WLCODE = (localStorage.getItem("signinWLAdmin") && localStorage.getItem("whitelabel_code")) ? localStorage.getItem("whitelabel_code") : (this.wl_code !== 1) ? this.wl_code : this.wlCode; 
        this.contentLoading = true;
        const payload = {
          page: this.currentPage,
          no_of_result: this.perPage,
          order: this.isSortDirDesc === true ? "DESC" : "ASC",
          is_resort_translation: true,
          resort: this.resortName,
          is_disabled: this.resortStatus !== null || this.resortStatus !== '' ? this.resortStatus : ''
        };
        console.log("payload", payload, WLCODE)
        await ResortTranslationService.getResortList(payload, WLCODE)
          .then((res) => {
            if (resConditionCheck(res.status) && res.data.data) {
              console.log("data", res.data.data)
              this.contentLoading = false;
              this.items2 = res.data.data;
              this.languages = this.languagesList.map((l) => {return {code: l.isoCode, name:l.name}})

              this.items = res.data.data.map((e) => {
                e.id = e.id;
                e.name;
                e.isDisabled = e.isDisabled;
                this.languages.map((l) => {
                  let find = e.resortMeta.findIndex((i) => l.code === i.langCode)
                  let value = find > -1 ?  e.resortMeta[find].name : "-"
                  e[l.name] = value
                })
                const obj = {...e}
                return obj;
              });

              this.totalResort = res.data.count;
              this.startIndex = this.currentPage * this.perPage - this.perPage;
              this.endIndex = this.startIndex + this.perPage;
            }
          })
          .catch((error) => {
            const errorData = errorResponseHandler(error);
            this.contentLoading = false;
            if (error.response.status === 404) {
              this.items = [];
              this.totalResort = 0;
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      },
  
      async getHotelDestination() {
        await BookingService.getHotelDestinationsList(this.wlCode).then(res => {
          if (resConditionCheck(res.status) && res.data) {
            this.DestinationList = res.data.data.map(e => ({
              label: `${e.name}, ${e.country}`,
              value: e.name,
            }));
          }
        }).catch(error => {
          console.error("Errrororororor",error)
          errorResponseHandler(error)
        })
      },
  
      openDisableModal(id, name, status) {
        this.resortId = id;
        this.status2 = !status;
        this.$refs.disableModal.show();
        this.disableMsg = !status
          ? `Are you sure you want to enable <strong class="text-primary">${name}</strong> resort ?`
          : `Are you sure you want to disable <strong class="text-primary">${name}</strong> resort ?`;
      },
      resetDisabledStatus() {
        const foundIdex = this.items.findIndex(
          (item) => item.id === this.resortId
        );
        this.$set(this.items[foundIdex], "isDisabled", this.status2);
      },
  
      addRemoveDisabled(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.disableModal.loading = true;
        if (this.resortId) {
          ResortTranslationService.disableResort(this.resortId)
            .then((res) => {
              this.$refs.disableModal.loading = false;
              this.$refs.disableModal.toggleModal = false;
              if (resConditionCheck(res.status)) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.getResortList();
              }
            })
            .catch((error) => {
              this.$refs.disableModal.loading = false;
              this.$refs.disableModal.toggleModal = false;
              const errorData = errorResponseHandler(error);
              this.resetDisabledStatus();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      },

      async getWhitelabelList() {
      this.isLoaded = false;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: "ASC",
        order_by: "name",
      };
      await whitelabelService
        .getWhitelableList(payload)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.isLoaded = true;
            this.whitelabelList = res.data.data.map((item) => {
              // if(!item.isDeleted) {
              return {
                label: item.name,
                value: item.id,
              };
              // }
            });
            this.whitelabelList.push({ label: "Beontrips", value: 1 });
          }
        })
        .catch((error) => {
          console.log("Error",error)
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
      },

      getWhiteLabelName(wlCode) {
      let label = this.whitelabelList.find(wl => (wl.value === wlCode))?.label
      return label ? label : 'Beontrips';
      },

      async languageList() {
        this.isLoaded = false;
        const payload = {
          no_of_result: 0,
          page: 1,
          order: "ASC",
          order_by: "name",
        };
        await LanguageService.getAllLanguages(payload)
          .then(res => {
            if (resConditionCheck(res.status) && res.data.data) {
              this.isLoaded = true;
              this.languagesList = res.data.data.map((item) => {
                return {
                  name: item.name,
                  id: item.id,
                  isoCode: item.iso_1Code
                };
              });
            }
          })
          .catch((error) => {
            console.log("Error",error)
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      },

      onCancel() {
        this.resortDetails = {}
        this.toggleModal = false;
      },

      openResortModal(data) {
        this.isLoaded = true;
        this.toggleModal = true;
        this.resortDetails = data
        this.$refs.resortTranslationModal.show();
      },

      async changeResortTranslations(translationForm) {
        const valid = await this.$refs.resortTranslationModal.validationForm();
        this.toggleModal = false;
        let resortsArr = {
          resorts: translationForm.resortMeta
        };
        if(valid) {
          try {
            await ResortTranslationService.addUpdateResortTranslation(translationForm.id, resortsArr)
            .then((res) => {
              this.loading = false;
              this.getResortList();
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: res.data.message,
                      icon: "X-CircleIcon",
                      variant: "success",
                  },
              });
            })
          } catch (error) {
              console.log("error", error);
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: errorData,
                    icon: "X-CircleIcon",
                    variant: "danger",
                },
              });
          }
        }
      },

      async getExportData() {
        this.exportResults = [];
        const WLCODE = (localStorage.getItem("signinWLAdmin") && localStorage.getItem("whitelabel_code")) ? localStorage.getItem("whitelabel_code") : (this.wl_code !== 1) ? this.wl_code : this.wlCode; 
        const payload = {
          no_of_result: 0,
          page: 1,
          is_resort_translation: true,
          resort: this.resortName,
          is_disabled: this.resortStatus !== null || this.resortStatus !== '' ? this.resortStatus : ''
        };
        await ResortTranslationService.getResortList(payload, WLCODE)
          .then(res => {
            if (resConditionCheck(res.status) && res.data.data) {
              let languages = this.languagesList
              res.data.data.forEach(e => {          
                let obj = languages.forEach(l => {
                  let name1 = '';
                  let country1 = '';

                  let translation = e.resortMeta.findIndex(x => { return x.languageId === l.id}) 
                  if(translation > -1) {
                    name1 = e.resortMeta[translation].name;
                    country1 = e.resortMeta[translation].country
                  }

                  this.exportResults.push({
                    Resort_ID: e.id,
                    Resort_name: e.name,
                    Language_ID: l.id,
                    Language_code: l.isoCode,
                    Name: name1,
                    Country: country1
                  })
                })
                return obj
              })
            }
          })
          .catch(error => {
            this.exportResults = this.items
          });
      },

      onChange(e) {
        const { files } = e.target;
        this.createFile(files[0]);
      },

      async createFile(file) {
        if (
          (file.type === "text/csv")
        ) {
          const formdata = new FormData()
          formdata.append('importFile', file)
          this.contentLoading = true
          await ResortTranslationService.importResortTranslation(formdata).then(res => {
            this.contentLoading = false
          if (resConditionCheck(res.status) && res.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          this.contentLoading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Only CSV files allowed.",
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        }
      },
      opendelayHotelModal(id, name, status) {
        this.resortId = id;
        this.status3 = !status;
        this.$refs.delayHotelModal.show();
        this.delayHotelMsg = !status
          ? `Are you sure you want to active delay search for <strong class="text-primary">${name}</strong> resort ?`
          : `Are you sure you want to inactive delay search for <strong class="text-primary">${name}</strong> resort ?`;
      },
      resetdelayHotelStatus() {
        const foundIdex = this.items.findIndex(
          (item) => item.id === this.resortId
        );
        this.$set(this.items[foundIdex], "extendHotelSearch", this.status3);
      },

      addRemovedelayHotel(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.disableModal.loading = true;
        if (this.resortId) {
          ResortTranslationService.activeInactiveSearchHotelDelay(this.resortId)
            .then((res) => {
              this.$refs.delayHotelModal.loading = false;
              this.$refs.delayHotelModal.toggleModal = false;
              if (resConditionCheck(res.status)) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.getResortList();
              }
            })
            .catch((error) => {
              this.$refs.delayHotelModal.loading = false;
              this.$refs.delayHotelModal.toggleModal = false;
              const errorData = errorResponseHandler(error);
              this.resetDisabledStatus();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .statusBtn {
    border: 1px solid;
    padding: 2px 10px !important;
    font-weight: bolder;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  .statusBtn:focus {
    background-color: #3f8d85!important;
    border-color: #3f8d85 !important
  }

  input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-autosuggest.scss";
  </style>
  