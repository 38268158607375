<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="resortTranslationModal"
      v-model="toggleModal"
      title="Change Resort Translation"
      ok-title="Submit"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-secondary"
      centered
      @ok="handleOk"
      @cancel="handleClose"
      @close="handleClose"
    >
      <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col class="pb-1" md="12">
            <label for="Resort">
              <h4>Resort</h4>
            </label>
                <b-form-input
                  id="name-input"
                  v-model="resortDetails.name" readonly
                />
          </b-col>
        </b-row>

        <b-row 
          v-for="(language, index) in languagesList"
          :key="index"
        >
        
          <label class="ml-1" for="LanguageName">
            <h4>{{language.name}}</h4>
          </label>
          <b-col md="12">
            <b-form-group 
              label="Name"
              label-for="mc-lang_name"
              label-cols-lg="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name-input"
                  v-model="language.resort"
                  :readonly="language.isoCode === 'EN' ? true : false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group 
              label="Country"
              label-for="mc-lang_country"
              label-cols-lg="4"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
              >
                <b-form-input
                  id="name-input"
                  v-model="language.country"
                  :readonly="language.isoCode === 'EN' ? true : false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
	BFormGroup,
	BFormInput,
	BModal,
	VBModal,
	BForm,
	BFormTextarea,
  BRow,
  BCol,
} from "bootstrap-vue";
import {
    resConditionCheck
  } from "@/helpers/constant";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from "@/@core/utils/validations/validations";
import vSelect from "vue-select";
import LanguageService from "@/services/language/language.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BFormGroup,
		BFormInput,
		BModal,
		BForm,
		vSelect,
		BFormTextarea,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider
	},
	directives: {
		"b-modal": VBModal,
		Ripple
	},

	props: {
    resortDetails: {
      type: Object,
      required: true
    },
		// isLoaded: {
		// 	type: Boolean,
		// 	required: true
		// },
    // toggleModal: {
    //   type: Boolean,
    //   default: false
    // }
	},

	data() {
		return {
			toggleModal: false,
      // showModal: this.toggleModal,
			required,
      resorts: [],
      languagesList: [],
      translationForm: {
        id: '',
        resortMeta: []
      }
		};
	},

	methods: {
		async show() {
      await this.languageList();
      this.toggleModal = true;
		},
		async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let vaild = await this.validationForm();
      if(vaild) {
        this.translationForm.id = this.resortDetails.id;
        this.translationForm.resortMeta = this.languagesList.map((e) => {
          return { language_id: e.id, name: e.resort, country: e.country }
        })
        
        this.$emit("on-submit", this.translationForm);
        this.$bvModal.hide('modal-prevent-closing')
      } else {
        this.$nextTick(() => {
          this.$bvModal.show('modal-prevent-closing')
        })
      }
    },
    handleClose() {
      this.$emit("on-cancel");
    },
    async validationForm() {
      let valid = false;
      await this.$refs.simpleRules.validate().then(success => {
        valid = !!success;
      });
      return valid;
    },

    async languageList() {
      this.isLoaded = false;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: "ASC",
        order_by: "name",
      };
      await LanguageService.getAllLanguages(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.isLoaded = true;
            this.languagesList = res.data.data.map((item) => {
              let find = this.resortDetails.resortMeta.findIndex((i) => item.iso_1Code === i.langCode)
              let valueName = '';
              let valueCountry = '';
              if(find >-1) { 
                valueName = this.resortDetails.resortMeta[find].name,
                valueCountry = this.resortDetails.resortMeta[find].country
              }
              return {
                name: item.name,
                id: item.id,
                isoCode: item.iso_1Code,
                resort: valueName,
                country: valueCountry
              };
            });
            console.log("languages", this.languagesList)
          }
        })
        .catch((error) => {
          console.log("Error",error)
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },
	}
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
