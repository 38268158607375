import axios from 'axios'

function getResortList(payload, wl_code) {
    let wlCode = axios.defaults.headers.wl_code
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
    const response = axios.get(`hotel/list`, { params: payload })
    axios.defaults.headers.wl_code = wlCode
    return response 
}

function addUpdateResortTranslation(id, data) {
    return axios.post(`hotel/resort-translation/${id}`, data)
}

function disableResort(id) {
    return axios.post(`hotel/disable-resort/${id}`)
}

function importResortTranslation(data) {
    return axios.post(`hotel/import-resort-translation`, data)
}

function activeInactiveSearchHotelDelay(id) {
    return axios.post(`hotel/delay-search-hotel/${id}`)
}

export default {
  getResortList,
  addUpdateResortTranslation,
  disableResort,
  importResortTranslation,
  activeInactiveSearchHotelDelay,
}
